import { graphql } from 'gatsby'
import React, { FC } from 'react'
import Layout from '@/containers/Layout'
import NewsContainer from '@/containers/NewsContainer'
import { News } from 'web/types/custom-graphql-types'
import SEO from '@/components/SEO'

interface NewsProps {
  data: {
    news: News
  }
}

const NewsPage: FC<NewsProps> = ({ data: { news: sanityNews } }) => (
  <Layout
    title="News"
    _type="news"
    i18n_lang={sanityNews.i18n_lang}
    i18n_refs={sanityNews.i18n_refs}
    i18n_base={sanityNews.i18n_base}
    seoSettings={sanityNews.seoSettings}
  >
    <NewsContainer news={sanityNews} />
  </Layout>
)

export default NewsPage

export const Head: FC<NewsProps> = ({ data: { news: sanityNews } }) => <SEO seoSettings={sanityNews.seoSettings} i18n_lang={sanityNews.i18n_lang} />

export const query = graphql`
  query ($id: String!) {
    news: sanityNews(id: { eq: $id }) {
      _id
      _key
      _type
      title
      author
      published
      _createdAt
      keyVisual {
        ...ImageWithPreview
      }
      category {
        title {
          de
          en
        }
      }
      excerpt
      tags {
        ...tagsData
      }
      _createdAt
      seoSettings {
        ...seoSettingsData
      }
      contentModulesNews {
        ...contentModulesNewsData
      }
      i18n_lang
      i18n_refs {
        ... on SanityNews {
          _type
          slug {
            current
          }
          i18n_lang
        }
      }
      i18n_base {
        ... on SanityNews {
          _type
          slug {
            current
          }
          i18n_lang
        }
      }
    }
  }
`
