import React, { FC, useEffect } from 'react'
import ModulesLoop from '@/modules'
import { News } from 'web/types/custom-graphql-types'
import NewsHeader from '@/components/NewsHeader'
import Box from '@/atoms/Box'

interface NewsContainerProps {
  news: News
}

const NewsContainer: FC<NewsContainerProps> = (props) => {
  const { news } = props

  return (
    <Box p={[2, 0]}>
      <NewsHeader news={news} />
      <ModulesLoop modules={news.contentModulesNews?.modules} templateType="news" />
    </Box>
  )
}

export default NewsContainer
