import * as React from 'react'
import { graphql, HeadProps, useStaticQuery } from 'gatsby'
import { mapToSingleObject } from '@/utils/helpers'
import { I18nLang, SeoSettings } from 'web/types/custom-graphql-types'

interface SEOProps extends HeadProps, I18nLang {
  title?: string
  seoSettings?: SeoSettings
}

const SEO = (props: SEOProps) => {
  const { seoSettings, i18n_lang } = props

  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettingsSeo {
        edges {
          node {
            seoSettings {
              robotsIndex
              seoTitle
              seoDescription
              ogImage {
                asset {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const { seoSettings: defaultSeoSettings } = mapToSingleObject(data)

  // if this is not the production environment, we don't want to index the site
  // noindex set in general siteSettings has priority over page-specific setting
  let indexSafe = 'noindex,nofollow'

  if (process.env.NODE_ENV === 'production') {
    indexSafe =
      defaultSeoSettings?.robotsIndex || seoSettings?.robotsIndex
        ? 'index,follow'
        : 'noindex,follow'
  }

  const ogImage = seoSettings?.ogImage || defaultSeoSettings?.ogImage
  return (
    <>
      <title> {seoSettings?.seoTitle || defaultSeoSettings?.seoTitle} </title>
      <meta name="robots" content={indexSafe} />
      <meta
        name="description"
        content={seoSettings?.seoDescription || defaultSeoSettings?.seoDescription}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
      />

      <link
        href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
        rel="stylesheet"
        type="text/css"
      />

      {ogImage && <meta property="og:image" content={ogImage.asset.url} />}
      <link rel="apple-touch-icon" sizes="120x120" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff"></meta>
    </>
  )
}

export default SEO
