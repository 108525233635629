import React, { FC } from 'react'
import Box from '@/atoms/Box'
import { News } from 'web/types/custom-graphql-types'
import Text from '@/atoms/Text'
import Image from '@/atoms/SanityImage'
import TagList from '../TagList'
import { toDateString } from '@/lib/date'
import ModuleContainer from '@/containers/ModuleContainer'

interface NewsHeaderProps {
  news: News
}

const moduleOptionsContainerWide = {
  padding: {
    container: false,
    x: 2,
    y: -1
  },
  theme: {
    theme: 'none',
    value: '#00ffe0'
  }
}

const moduleOptionsContainerNarrow = {
  padding: {
    container: true,
    pt: 4,
    pr: 15,
    pb: 4,
    pl: 15
  },
  theme: {
    theme: 'none',
    value: '#00ffe0'
  }
}

const NewsHeader: FC<NewsHeaderProps> = (props) => {
  const { title, keyVisual, category, excerpt, tags, author, published, _createdAt, i18n_lang } =
    props.news

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pt={6}>
      <ModuleContainer options={moduleOptionsContainerWide}>
        {category && (
          <Box pb={[2, 4]} display={['block', 'none']} className="fade-in">
            <Text variant="bodyS" textAlign="center">
              {category.title[i18n_lang || 'en']}
            </Text>
          </Box>
        )}
        <Text
          variant="h2"
          component="h1"
          textAlign="center"
          className="fade-in"
          pt={[0, 2]}
          pl={[0, 5]}
          pr={[0, 5]}
          pb={[2, 2]}
        >
          {title}
        </Text>
        {keyVisual && (
          <Box display={['block', 'none']} className="fade-in move-in">
            <Image image={keyVisual} aspectRatio="1"></Image>
            {keyVisual?.asset?.description && (
              <Box py={[1, 2]} px={[2, 0]} className={'fade-in move-in"'}>
                <Text variant="date">{keyVisual.asset.description}</Text>
              </Box>
            )}
          </Box>
        )}
        {keyVisual && (
          <Box display={['none', 'block']} px={2} py={5} className="fade-in move-in">
            <Image image={keyVisual} aspectRatio="16/7"></Image>
            {keyVisual?.asset?.description && (
              <Box py={[1, 2]} px={[2, 0]} className={'fade-in move-in"'}>
                <Text variant="date">{keyVisual.asset.description}</Text>
              </Box>
            )}
          </Box>
        )}
      </ModuleContainer>
      <ModuleContainer options={moduleOptionsContainerNarrow}>
        {category && (
          <Box py={2} display={['none', 'block']} className="fade-in">
            <Text variant="date"> {category.title[i18n_lang || 'en']}</Text>
          </Box>
        )}
        {excerpt && (
          <Box py={2} width="100%" className="fade-in" display={['block', 'none']}>
            <Text component="p" variant="bodyL">
              {excerpt}
            </Text>
          </Box>
        )}
        {excerpt && (
          <Box py={2} width="100%" className="fade-in" display={['none', 'block']}>
            <Text component="p" variant="bodyXL">
              {excerpt}
            </Text>
          </Box>
        )}
        {tags && (
          <Box py={2} width="100%" className="fade-in">
            <TagList tags={tags} numberOfTags={tags.length} i18n_lang={i18n_lang} />
          </Box>
        )}
        {author && (
          <Text py={2} variant="date" className="fade-in" pb={1}>
            by {author}
          </Text>
        )}
        {(published || _createdAt) && (
          <Text py={2} variant="date" className="fade-in">
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              published ? toDateString(published, i18n_lang) : toDateString(_createdAt, i18n_lang)
            }
          </Text>
        )}
      </ModuleContainer>
    </Box>
  )
}

export default NewsHeader
